.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh; /* Adjusted to min-height for better responsiveness */
  background-color: #f4f4f4; /* Soft background color for better contrast */
  padding: 40px 20px; /* Additional padding for better spacing */
}

.content {
  text-align: center;
  padding: 40px;
  background-color: #ffffff; /* White background for the content box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  max-width: 500px; /* Max width for better readability */
}

.title {
  font-size: 28px; /* Larger font size for title */
  margin-bottom: 15px;
  color: #333333; /* Darker color for better readability */
}

.description {
  font-size: 18px;
  margin-bottom: 25px;
  color: #555555; /* Slightly lighter text color */
}

.logo {
  width: 150px; /* Adjusted size */
  height: auto; /* Auto height for maintaining aspect ratio */
  object-fit: contain;
  margin-bottom: 30px; /* Increased margin */
}

.error-title, .error-description {
  font-size: 24px;
  color: #d9534f; /* Adjusted color for a softer red */
  margin-bottom: 15px;
}

.error-description {
  font-size: 18px;
  margin-bottom: 25px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .content {
    padding: 20px;
    max-width: 100%;
  }

  .title, .error-title {
    font-size: 24px;
  }
}

.loadingIcon {
  width: 50px;
  height: auto;
  margin: 20px auto; /* Center the icon */
  display: block;
  padding-top: 100px;
  
}

.countdown {
  font-size: 16px;
  color: #333; /* Customize color as needed */
  font-weight: bold;
  margin-top: 20px; /* Adjust spacing as needed */

}