@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

* {
    font-family: 'Lato', sans-serif;
}

.container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh;
    background-color: #ffffff;
  }
  
  .video-section {
    text-align: center;
    padding: 20px;
    background-image: url('../assets/image/travel4.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-grow: 1; 
  }
  

.video-section .iframe-container {
    max-width: 560px;
    margin: 0 auto;
    padding: 20px 0; 
}


.video-section .iframe-container::before {
    display: block;
    content: "";
    width: 100%;
}

.video-section h1 {
    font-size: 24px;
    color: #231f20; 
    margin-bottom: 15px;
}

.video-section h4 {
    font-size: 18px;
    color: #4ed4ff; 
    margin-bottom: 10px;
}

.video-section h3 {
    font-size: 20px;
    color: #f25700; 
    margin-bottom: 20px;
}

.video-section iframe {
    width: 100%;
    height: 315px; 
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center; 
  }
  
  .big-text, .mechanics {
    font-size: 60px; 
    font-weight: bold; 
    color: #fff; 
    background-color: #4ed4ff; 
    padding: 10px;
    margin-bottom: 20px; 
    border-radius: 15px;
}
  
.mechanics{
    color: #f25700; 
    margin-bottom: 20px;    
    background-color: #fff;
    text-transform: uppercase;
    display: inline-block;
    font-size: 20px;
    
}

.blinking-icon {
    animation: blink 1s linear infinite;
    color: #f25700; 
    font-size: 48px;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.member-now-section {
    padding: 20px;
    background-color: #0097d8; 
    text-align: center;
    color: #ffffff; 
    margin: 20px 0;
    border-radius: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
}

.member-now-text{
    font-size: 48px;
    text-transform: uppercase;
    display: inline-block;
    color: #ffd304; 
    margin-bottom: 50px;
}

.lock-in-text{
    font-size: 25px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 15px;
}

.button {
    background-color: #f25700; 
    color: #ffffff; 
    border: none; 
    padding: 15px 30px; 
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s; 
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .button:hover {
    background-color: #0097d8; 
  }
  
.lock-in-text-italic{
    font-size: 20px;
    display: inline-block;
    margin-bottom: 15px;
    font-style: italic;
    color: #ffd304; 
}


.video-section-footer {
    text-align: center;
    padding: 20px;
    flex-grow: 1; 
  }
  

.video-section-footer .iframe-container {
    max-width: 560px;
    margin: 0 auto;
    padding: 20px 0; 
}


.video-section-footer .iframe-container::before {
    display: block;
    content: "";
    width: 100%;
}

.video-section-footer iframe {
    width: 100%;
    height: 315px; 
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-right: 8px; 
    vertical-align: middle;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.submitButton {
    display: flex;     
    justify-content: center;
    align-items: center; 
    flex-direction: row; 
}

.loading-text {
    vertical-align: middle; 
}