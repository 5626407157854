/* Container for the entire referral program section */
.get-it-free-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
  }
  
  /* Header styles for the section */
  h2 {
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Paragraph text styling */
  p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Styling for list of steps or benefits */
  .referral-steps {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    text-align: left;
  }
  
  .referral-steps li {
    background-color: white;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .referral-steps li:hover {
    transform: translateY(-2px);
  }
  
  /* Testimonial and additional information section */
  .testimonials {
    margin-top: 30px;
  }
  
  /* Call to action button styling */
  .cta-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    color: white;
    background-color: #f62;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: rgb(193, 67, 13);
  }
  
  /* Information graphic styling, if applicable */
  .referral-info-graphic {
    text-align: center;
    margin: 20px 0;
  }
  
  /* Justified text styling for paragraphs */
  .justified-text {
    text-align: justify;
    text-justify: inter-word; /* This ensures spacing is adjusted between words for better alignment */
  }
  
  /* Add media queries for responsiveness */
  @media (max-width: 768px) {
    .get-it-free-section {
      margin: 10px;
      padding: 10px;
    }
  
    .referral-steps li {
      font-size: 14px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  }
  