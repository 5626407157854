body { background-color: #e9eaef;}

.container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh;
}

h1 {
    font-family: 'Arial', sans-serif; 
    font-size: 28px; 
    color: #ffd304; 
    text-align: center; 
    margin-bottom: 20px; 
}

h4 {
    font-family: 'Arial', sans-serif;
    font-size: 20px; 
    color: #d6e4eb;
    text-align: center;
    margin-bottom: 15px;
}

h3 {
    font-family: 'Arial', sans-serif;
    font-size: 22px; 
    color: #d6e4eb;
    text-align: center;
    margin-bottom: 20px;
}

.top-section {
    padding: 10px;
    background-color: #e9eaef !important;
}

.top-section h1 {
    color: #000;
}

.video-section {
    text-align: center;
    background-color: #e9eaef !important;
    padding: 20px;
}

.video-section .iframe-container {
    max-width: 560px;
    margin: 0 auto;
    padding: 20px 0; 
}


.video-section .iframe-container::before {
    display: block;
    content: "";
    width: 100%;
}

.video-section h1 {
    font-size: 24px;
    color: #231f20; 
    margin-bottom: 15px;
}

.video-section h4 {
    font-size: 18px;
    color: #4ed4ff; 
    margin-bottom: 10px;
}

.video-section h3 {
    font-size: 20px;
    color: #f25700; 
    margin-bottom: 20px;
}

.video-section iframe {
    width: 100%;
    height: 315px; 
}

.product-highlight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-info {
    flex: 1;
    padding-right: 20px; /* Adjust as needed */
}

.product-info h2, .product-info h3 {
    color: #333;
    margin-bottom: 10px;
}

.product-description, .feature-description {
    color: #555;
    font-size: 1em;
    line-height: 1.6;
    text-align: justify;
}

.product-image {
    flex: 1;
    text-align: center; 
}

.product-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; 
}

.benefits-section {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.benefits-section h2 {
    color: #333;
    margin-bottom: 20px;
}

.benefit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding: 10px;
}

.benefit-icon {
    /* width: 50px;  */
    height: 50px; /* Adjust size as needed */
    margin-right: 20px;
}

.benefit h3 {
    margin-bottom: 5px;
    color: #333;
}

.benefit p {
    color: #555;
    font-size: 1em;
    line-height: 1.6;
    flex-grow: 1; /* Allows text to take available space */
}

.signup-section {
    display: flex; 
    align-items: center;
    justify-content: space-around; 
    padding: 40px; 
    background-image: url('../assets/image/travel2.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 400px; 
    background-color: #f1f1f1; 
    padding: 40px;
    align-items: center;
    justify-content: flex-start; 
    
    
    color: #ffffff; 
}

.steps-section {
    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 20px; 
    padding: 20px;
    background-color: #f25700; 
    color: #ffffff;
}


.more-info-box {
    background-color: #0097d8; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin-right: auto;
    font-family: 'Arial', sans-serif; 
}

.more-info-box h1 {
    font-size: 24px; 
    margin-bottom: 15px;
}

.more-info-box h5 {
    font-size: 16px;
    margin-bottom: 20px;
}

.more-info-box input {
    display: block;
    width: 80%; 
    padding: 10px;
    margin-bottom: 10px;
    margin-left: auto; 
    margin-right: auto; 
    border-radius: 4px;
    border: 1px solid #fff; 
    background-color: #ffffff; 
    color: #000000; 
    font-size: 16px; 
}

.more-info-box button {
    width: 100%;
    padding: 12px;
    background-color: #f25700; /* Orange from your color palette */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Button shadow for depth */
}

.more-info-box button:hover {
    background-color: #d24600; /* Darker shade for hover effect */
}

.comment-section {
    background-color: #f8f8f8; 
    padding: 20px;
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin: 20px 0; 
    background-color: #e9eaef;
}

.testimonial {
    display: flex; 
    align-items: center;
    margin-bottom: 20px;

    justify-content: center;
    flex-direction: row;
}

.testimonial img {
    width: 100px; 
    height: 100px; 
    border-radius: 50%; 
    margin-right: 20px; 

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.testimonial h3 {
    color: #333; 
    font-size: 1.2em;
    margin-bottom: 4px; 
    color: #231f20;
}

.testimonial p {
    color: #666;
    font-style: italic;
}
.testimonial hr {
    border: none;
    height: 1px;
    background-color: #ddd; 
    margin: 20px 0; 
}

.testimonial div {
    margin-bottom: 20px;
}

.shake-animation {
    animation: shake 0.5s;
    animation-iteration-count: 2;
}
  
@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }     
}
  
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-right: 8px; 
    vertical-align: middle;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.submitButton {
    display: flex;       /* Enable Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.loading-text {
    vertical-align: middle; /* Align text vertically */
}





.testimonialItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff; /* Consider a light background */
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  }
  
  .testimonialImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .testimonialName {
    margin: 0;
    color: #231f20; /* Dark text for name */
    font-weight: bold;
  }
  
  .testimonialTitle {
    margin: 5px 0;
    color: #0097d8; /* Blue color for title */
    font-size: 1em;
  }
  
  .testimonialPosition {
    margin: 5px 0;
    color: #f25700; /* Orange color for position */
    font-size: 0.9em;
  }
  
  .testimonialQuote {
    margin: 5px 0;
    color: #666;
    font-style: italic;
    text-align: justify;
  }


.moreInfoLink {
    color: #0097d8; /* Example color - adjust as needed */
    font-weight: bold;
    margin-left: 10px; /* Spacing from the quote */
    text-decoration: none; /* Optional: removes underline */
  }
  
  .moreInfoLink:hover {
    text-decoration: underline; /* Optional: underline on hover */
  }
  
  .modal {
    display: flex;
    align-items: center; /* Vertically centers the modal */
    justify-content: center; /* Horizontally centers the modal */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  
  .modalContent {
    width: 90%; /* Responsive width */
    max-width: 500px; /* Maximum width for larger screens */
    margin: auto; /* Ensures centering */
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    text-align: center;

    max-height: 80vh; /* 80% of the viewport height */
  overflow-y: auto; /* Makes the content scrollable */
  padding-bottom: 20px;
  }
  
.modalContent p {
text-align: justify;
text-justify: inter-word; 
}
.modalContent h3 {
color: #333; 

}

.modalContent h4 {
    color: #555; 

}

.modalImage {
max-width: 200px; 
height: auto;
border-radius: 50%; 
margin-bottom: 10px;
display: block; 
margin-left: auto; 
margin-right: auto;
}

.closeButton {
padding: 10px 20px;
background-color: #0097d8; 
color: white;
border: none;
border-radius: 5px;
font-size: 16px;
font-weight: bold;
cursor: pointer;
transition: background-color 0.3s ease;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
outline: none; 
}

.closeButton:hover {
    background-color: #0077b3; 
}

.closeButton:focus {
    box-shadow: 0 0 0 2px rgba(0, 151, 216, 0.5); 
}


.top-section {
    padding: 30px 10px;
    background-color: #f8f8f8;
    text-align: center; 
    border-bottom: 3px solid #d6e4eb; 
}

.top-section h1 {
    color: #333;
    font: 600 40px/1 "Poppins",sans-serif;
    margin-bottom: 15px;
    font-weight: bold; 
}

.top-section .description {
    color: #555;
    font: 300 16px/41px "Poppins",sans-serif;
    letter-spacing: -.2px;
    line-height: 1.5;
    max-width: 800px; 
    margin: 0 auto; 
    padding: 0 20px; 
}

.features-section {
    padding: 40px; 
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 40px auto; 
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    max-width: 1200px; 
}

.feature {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 30px;
    padding: 20px; 
    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 2px 6px rgba(0,0,0,0.05); 
}

.feature-icon {
    width: 100px; 
    height: auto; 
    margin-right: 30px;
    border-radius: 8px;
}

.feature h3 {
    color: #333;
    font-size: 1.25rem; 
    margin-bottom: 10px; 
}

.feature p {
    color: #666; 
    font-size: 1rem; 
    text-align: left;
    flex: 1; 
}

@media screen and (max-width: 360px) { 
    .orderButton {margin:0; padding:0;}
    
}

@media screen and (max-width: 600px) { 
    
    .top-section h1 { font-size: 1.5em; }
    .description { text-align: justify; }    

    .testimonialItem {
        flex-direction: column; 
        align-items: center; 
        text-align: center; 
    }

    .testimonialImage {
        width: 80px;
        height: 80px;
        margin-bottom: 10px; 
    }

    .testimonial {
        flex-direction: column; 
    }

    .testimonial img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .modalContent {
        width: 95%; 
        padding: 15px; 
        max-height: 90vh;
    }

    .modalImage {
     max-width: 150px; 
    }     

    /* .feature-icon {
        height: 200px;
    }     */
}

@media screen and (max-width: 768px) { 
    .benefit {
        flex-direction: column;
        align-items: center;
    }

    .benefit-icon {
        margin-bottom: 10px;
    }

    .product-highlight {
        flex-direction: column;
    }

    .product-info, .product-image {
        padding: 10px;
        text-align: center;
    }  
    
    .feature {
        flex-direction: column;
        text-align: center;
        justify-content: flex-start;
      }
    
      .feature-icon {
        margin-bottom: 10px;
        margin-right: 0;
      }    
}

@media screen and (min-width: 1024px) { 
    .features-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        text-align: left; 
    }

    .feature {
        flex-direction: column; 
        align-items: center;
        padding: 30px;
    }

    .feature-icon {
        margin-bottom: 20px; 
    }
}


.product-highlight {
    display: flex;
    align-items: stretch; /* Aligns children of the container */
    justify-content: space-between; /* Distributes space between and around content items */
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-info {
    flex: 1 1 50%; /* Allows the box to grow and shrink but not beyond 50% of the flex container */
    padding: 20px; /* Adjust as needed */
    display: flex;
    flex-direction: column; /* Stacks the content vertically */
}

.product-image {
    flex: 1 1 50%; /* Adjusts this flex child to take up 50% of the flex container */
    text-align: center; /* Centers the image */
    padding: 20px;
}

.product-info, .product-image {
    border: 1px solid #ddd; /* Optional, adds a border around each flex item */
    border-radius: 8px; /* Rounds the corners of the border */
    background-color: #fff; /* Sets a white background for visibility */
    box-shadow: 0 2px 6px rgba(0,0,0,0.1); /* Adds a shadow for depth */
}


@media (max-width: 768px) {
    .product-highlight {
        flex-direction: column;
    }

    .product-info, .product-image {
        flex: 1 1 100%; /* Each takes full width on small screens */
        padding: 10px;
        text-align: center;
    }
}


.AccordionSummaryLabel{
    color: #000 !important;
}


.product-info ul {
    list-style-type: none; /* Removes default list bullets */
    padding: 0; /* Removes default padding */
    margin: 0; /* Adjusts margin */
}

.product-info li {
    background-color: #f8f8f8;
    border: 1px solid #ddd; /* Adds a subtle border */
    padding: 12px 16px; /* Larger padding for better tap targets */
    margin-bottom: 8px; /* Adds space between items */
    border-radius: 5px; /* Softens the corners */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions for hover */
    font-size: 16px; /* Increases font size for readability */
}

.product-info li:hover {
    background-color: #e9e9e9; /* Lighter background on hover */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Adds depth on hover */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .product-info li {
        padding: 15px 20px; /* Even larger padding on mobile */
        font-size: 18px; /* Larger font size for easier reading */
        box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Always on shadow for better layering */
    }
}
