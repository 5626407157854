.footer-section {
    /* width: 100%; */
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    padding: 20px; 
    background-color: #fff; 
    color: #000; 
}

.social-icon {
    width: 30px;
    height: 30px; 
    margin: 0 10px; 
}
  

.social-icons {
    display: flex; 
    justify-content: center; 
    align-items: center; 
}  
  
.logo-footer {
    max-width: 200px; 
    max-height: 100px; 
    width: auto; 
    height: auto;
    margin-right: 15px;
    margin-bottom: 10px;
}  