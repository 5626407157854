.header {
    display: flex;
    align-items: center;
    padding: 10px;

    justify-content: space-around;
    width: 100%;
    
}

.header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.logo {
    max-width: 150px;
    height: 40px;

    flex-shrink: 0;
}

.nav {
    display: flex;
    align-items: center;

    flex-grow: 1; 
    justify-content: center; 
    max-width: calc(80% - 170px);    
}

.navList {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;


    width: 60%; 
    justify-content: space-between; 
}

.nav, .navList {
    display: flex;
    align-items: center; 
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navItem:hover {
    color: #f62;
}

.navItem {
    margin-right: 20px;
    cursor: pointer;
}

.orderButton {
    padding: 10px 20px; 
    background-color: #f62; 
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;

    flex-shrink: 0; 
}

.orderButton:hover {
    background-color: rgb(193, 67, 13);
    color:#fff; 
}


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    
    width: 60%;
    height: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow-y: auto;
  }
  
  .modalCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 20px;
  }
  

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }



.item-slider img {
    width: 100%; 
    height: auto; 
    display: block; 
}

.item-slider div {
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 100%; 
}

.item-slider {
    margin-bottom: 20px;
}

.item-details {
    text-align: center;
}

.desc p {
    text-align: justify; 
}

.category, .name, .model, .desc {
    margin-bottom: 10px;
}

.category {
    font-size: 1.2em;
    color: #666;
}

.name {
    font-size: 1.5em;
    font-weight: bold;
}

.model {
    font-size: 1em;
    color: #888;
}

.price {
    margin-bottom: 15px;
    font-size: 1.3em;
    color: #333;
}

.amount {
    font-weight: bold;
}

.badge {
    display: block; 
    margin-top: 5px;
    font-size: 0.9em;
    color: #f62;
}

.pre-order {
    padding: 12px 25px;
    background-color: #f62;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.3s ease;
}

.pre-order:hover {
    background-color: #e5503c;
}

.modalContent {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    flex-wrap: wrap; 
    gap: 20px; 
}
  
.item-slider, .item-details {
    flex: 1; 
    padding: 20px;
    box-sizing: border-box; 
    max-width: 50%; 
}

.modalNarrow {
    width: 50%;
}

.radio-options {
    display: flex;
    justify-content: start;
    gap: 20px; 
    padding-left: 20px; 
 
}

.radio-input {
    margin-right: -10px;
}

.orderContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.orderContent input[type="text"],
.orderContent input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;

}

.orderContent button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.orderContent button:hover {
    opacity: 0.8;
}

/* .note {
    color: #ff0000;
    margin-top: 10px;
    font-size: 12px;
} */

.note {
    color: #666;
    font-style: italic;
    margin-top: 10px;
  }
.order-type-section {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc; 
    border-radius: 8px; 
    background-color: #f9f9f9; 
}

.legend-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600; 
}

.radio-option, .quantity-input {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

}

.quantity-input {
    margin-left: 27px;
}

.radio-option input[type="radio"], .quantity-input input[type="number"] {
    margin-right: 10px;
    cursor: pointer; 
}

.radio-option label, .quantity-input label {
    margin: 0; 
    font-weight: 500;
}

input[type="radio"] {
    accent-color: #007bff; 
}

input[type="number"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80px; 
    margin-left: 5px;
}

input[type="number"]:focus {
    outline: none;
    border-color: #007bff; 
}


.radio-option label:hover, .quantity-input label:hover {
    color: #0056b3;
}

.deliveryAddressTextarea {
    height: 100px; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    display: inline-block;
    margin-left: 10px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.terms {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px; 
    background-color: #f2f2f2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .terms-info {
    color: #333; 
    font-size: 16px; 
    font-weight: bold; 
  }
  

@media screen and (max-width: 360px) { 
    .modal{padding: 0;}
    
    .nav {
        max-width: calc(90% - 176px);    
    }    

    .orderButton {
        margin-left: 30px;
    }    

    .orderContent { padding: 30px !important;}
    .item-slider {
        padding: 35px !important;
    }    

    .radio-options {
        gap: 12px; 
        padding-left: 0; 
     
    }
    
}

@media (max-width: 768px) {
    .order-type-section {
        flex-direction: column;
    }

    .modalContent {
        flex-direction: column;
    }

    .item-slider, .item-details {
        max-width: 100%; 
    }
    
    
    .item-details {
        text-align: left;
    }

    .nav {
        flex-direction: column;
        align-items: flex-start;
        
    }

    .navList {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .navItem {
        margin: 10px 0;
        width: 100%;
        text-align: left;
    }

    .orderButton {
        width: 100%;
    }

    .logo {
        max-width: 120px;
        height: auto;
    }

    .navList li:not(:last-child) {
        display: none;
    }

    .header {
        padding: 10px 0;
    }
    
    .modal {
        width: 95%; 
        max-height: 90vh; 
    }

    .terms {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 8px;
    }
    
    .terms-info {
    font-size: 14px; 
    }
}










.free-shipping-banner {
    background-color: #4CAF50;
    color: white; 
    text-align: center; 
    padding: 10px 0; 
    font-size: 1em; 
    font-weight: bold; 
    margin: 10px 0 20px 0; 
    border-radius: 5px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); 
  }
  
  @media (max-width: 768px) {
    .free-shipping-banner {
      font-size: 0.9em; 
      padding: 8px 0; 
    }
  }
  







  .deposit-amount-section h3 {
    margin: 20px 0 10px;
    color: #333;
  }
  
  .deposit-amount {
    font-size: 22px;
    color: #007bff; 
    font-weight: bold;
    text-align: center;
  }
  
  .deposit-amount strong {
    font-size: 26px;
  }
  
  /* .savings {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    color: #4CAF50;
  } */



  .savings {
    color: #28a745; /* Green color for savings message */
    font-weight: normal;
    font-size: 16px;
  }


  .two-column-flex {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
  }
  
  .order-type-section {
    flex-basis: 40%; 
    max-width: 40%; 
  }
  
  .column-content {
    display: flex;
    flex-direction: column;
    gap: 20px; 
    margin-top: 12px;
    width: 100%;
  }
  
  .column-content input, .column-content textarea {
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 5px; 
    font-size: 16px; 
  }
  
  .column-content input:focus, .column-content textarea:focus {
    border-color: #007bff; 
    outline: none; 
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25); 
  }
  
  .visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
 

  @media (max-width: 768px) {
    .two-column-flex {
      flex-direction: column;
    }
  
    .order-type-section, .column-content {
    flex-basis: 100%;
      max-width: 100%;
    }
  }
  

  .order-type-section, .column-content {
    flex: 1; /* This allows each column to grow equally, filling the container */
    min-width: 0; /* This prevents flex items from overflowing their container */
  }
  




  .total-amount-section, .deposit-amount-section {
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background for distinction */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .total-amount-section h3, .deposit-amount-section h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .total-amount, .deposit-amount {
    font-size: 20px;
    font-weight: bold;
    color: #007bff; /* Highlight color for amounts */
  }
  

  .amount-and-savings {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Center-aligns the children */
    gap: 5px; 
    font-size: 26px;
  }
  
  .amount-and-savings strong, .amount-and-savings span {
    text-align: center; /* Ensures text in both elements is centered */
  }
  